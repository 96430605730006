export default {
  blogerName: 'CHUP',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCt5Ui7udY79MMebNzAT_NQw',
    }
  ],
  projects: [
    {
      name: 'sol',
      url: 'https://solcasino.life/c1b6863c9',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c8139b132',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ca68de316',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c854a84ad',
      gameTitle: 'Jet Air (BGaming)',
    },
  ],
  headerText: 'Regístrate con mi código promocional <strong>CHUP</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CHUP',
  casinoBonus1: '<strong>100%</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
